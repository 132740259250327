import axios from 'axios'
import {SERVER_HOST} from './../common/portConfig'
var instance = axios.create({
    headers: {
        'Authorization': ''
    }
});
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = sessionStorage.getItem('Authorization');
        return config
    }, err => {
        return Promise.reject(err)
    });


// 一日游
export const reqGetTourListPage = params => { return instance.get(`${SERVER_HOST}/admin/tour/page`,{ params: params }) };
export const reqAddTour = params => { return instance.post(`${SERVER_HOST}/admin/tour/add`, params) };
export const reqEditTour = params => { return instance.post(`${SERVER_HOST}/admin/tour/edit`, params) };
export const reqDeleteTour = params => { return instance.delete(`${SERVER_HOST}/admin/tour/delete/`+params.id, params) };
export const reqBatchDeleteTour = params => { return instance.post(`${SERVER_HOST}/admin/tour/batchdelete/`, params) };
export const reqGetTourOne = params => { return instance.get(`${SERVER_HOST}/admin/tour/one`, { params: params }) };
export const reqGetTourGroupList=params => { return instance.get(`${SERVER_HOST}/admin/tour/tourgrouplist`, params) };

export const reqGetTourGroupListPage = params => { return instance.get(`${SERVER_HOST}/admin/tourGroup/list`,{ params: params }) };
export const reqAddTourGroup = params => { return instance.post(`${SERVER_HOST}/admin/tourGroup/add`, params) };
export const reqEditTourGroup = params => { return instance.post(`${SERVER_HOST}/admin/tourGroup/edit`, params) };
export const reqDeleteTourGroup = params => { return instance.delete(`${SERVER_HOST}/admin/tourGroup/delete/`+params.id, params) };
export const reqBatchDeleteTourGroup = params => { return instance.post(`${SERVER_HOST}/admin/tourGroup/batchdelete/`, params) };
export const reqGetTourGroupOne = params => { return instance.get(`${SERVER_HOST}/admin/tourGroup/one`, { params: params }) };


