<template>
    <el-row class="warp">
        <el-col :span="24" class="warp-breadcrum">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/' }"><b>首页</b></el-breadcrumb-item>
                <el-breadcrumb-item>内容管理</el-breadcrumb-item>
                <el-breadcrumb-item>一日游</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/tourGroup/list' }">旅行社</el-breadcrumb-item>
                <el-breadcrumb-item>新增</el-breadcrumb-item>
            </el-breadcrumb>
        </el-col>
        <el-col :span="24" class="warp-main">
            <el-form ref="form" :model="form"  :rules="rules" label-width="150px" class="el-form-self">

                <el-row>
                    <el-col>
                        <el-form-item label="名称:" prop="name">
                            <el-input v-model="form.name" auto-complete="off" placeholder="请输入名称" show-word-limit maxlength="50" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="简介:" prop="introduction">
                            <el-input type="textarea" v-model="form.introduction" auto-complete="off" show-word-limit placeholder="请输入景色简介" clearable maxlength="1000"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col>
                        <el-form-item label="联系电话:" prop="contactNumber">
                            <el-input v-model="form.contactNumber" auto-complete="off" show-word-limit placeholder="请输入名称" maxlength="12" clearable></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="地址:" prop="address" class="is-required">
                            <el-input v-model="form.address" auto-complete="off" show-word-limit placeholder="请输入地址" clearable maxlength="100"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="星级评分:" prop="introduction">
                            <el-rate v-model="form.rate" show-score text-color="#ff9900" score-template="{value}颗星"></el-rate>
                        </el-form-item>
                    </el-col>
                </el-row>


                <el-row>
                    <el-col>
                        <el-form-item label="列表缩略图片:" prop="indexImageUrl" class="is-required">
                            <el-upload
                                    class="homeBannerImage-uploader"
                                    :action="uploadFileURL"
                                    :show-file-list="false"
                                    :on-success="handleIndexImageSuccess"
                                    :before-upload="beforeIndexImageUpload">
                                <img v-if="indexImageUrl" :src="indexImageUrl" class="homeBannerImage">
                                <i v-else class="el-icon-plus homeBannerImage-uploader-icon"></i>
                                <div slot="tip" class="el-upload__tip">注意：点击 + 上传.jpg, .png后缀图片文件。图片尺寸为220px x 220px。</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="详情页图片:" prop="detailImageUrl" class="is-required">
                            <el-upload
                                    class="homeBannerImage-uploader"
                                    :action="uploadFileURL"
                                    :show-file-list="false"
                                    :on-success="handleDetailImageSuccess"
                                    :before-upload="beforeDetailImageUpload">
                                <img v-if="detailImageUrl" :src="detailImageUrl" class="homeBannerImage">
                                <i v-else class="el-icon-plus homeBannerImage-uploader-icon"></i>
                                <div slot="tip" class="el-upload__tip">注意：点击 + 上传.jpg, .png后缀图片文件。图片尺寸为720px x 480px。</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                   <el-row>
                    <el-col>
                        <el-form-item label="旅行社Logo:" prop="log" class="is-required">
                            <el-upload
                                    class="homeBannerImage-uploader"
                                    :action="uploadFileURL"
                                    :show-file-list="false"
                                    :on-success="handleLogSuccess"
                                    :before-upload="beforeLogUpload">
                                <img v-if="log" :src="log" class="homeBannerImage">
                                <i v-else class="el-icon-plus homeBannerImage-uploader-icon"></i>
                                <div slot="tip" class="el-upload__tip">注意：点击 + 上传.jpg, .png后缀图片文件。图片尺寸为720px x 480px。</div>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="置顶:" prop="isTop">
                            <el-radio-group v-model="form.isTop" placeholder="请选择置顶">
                                <el-radio label="0">是</el-radio>
                                <el-radio label="1">否</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row class="form-buttons">
                    <el-col>
                        <el-form-item class="el-form-item-self">
                            <el-button type="primary" @click="onSubmit">提交</el-button>
                            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>

            </el-form>


        </el-col>
    </el-row>

</template>

<script>
    import {reqAddTourGroup} from '../../../api/tour-api'
    import {uploadFileURL,downloadFileURL} from '../../../api/system-api'
    export default {
        data(){
            return{
                uploadFileURL:uploadFileURL,
                downloadFileURL:uploadFileURL,
                indexImageUrl: '',
                detailImageUrl: '',
                log:'',
                form: {
                    name: '',
                    contactNumber:'',
                    indexImageUrl: '',
                    detailImageUrl: '',
                    isTop:'1',
                    introduction:'',
                    rate:null,
                    address:'',
                    log:''
                },
                rules: {
                    name: [
                        {required: true, message: '请输入名称', trigger: 'blur'},
                    ],
                    contactNumber: [
                        {required: true, message: '请输入联系电话', trigger: 'blur'},
                        {pattern: /^((0\d{2,3})-)(\d{7,8})(-(\d{3,}))?$|^(400-)(\d{7})$|^1[3|4|5|7|8|9][0-9]{9}$/, message:'请输入正确的联系电话',trigger: 'blur'},
                    ],
                    address:[
                        {required: true, message: '请输入地址', trigger: 'blur'},
                    ],
                    isTop:[
                        {required: true, message: '请选择是否置顶'},
                    ],

                    indexImageUrl: [
                        {required: true, message: '请上传列表缩略图片', trigger: 'blur'},
                    ],
                    detailImageUrl: [
                        {required: true, message: '请上传详情页图片', trigger: 'blur'},
                    ],
                },
            }
        },
        methods:{
            //提交
            onSubmit() {
                var that = this;
                this.$refs.form.validate((valid) => {
                    if (valid) {
                        if(!that.form.indexImageUrl){
                            that.$message({
                                message: '请输入列表缩略图片',
                                type: 'warning'
                            });
                            return false;
                        }

                        if(!that.form.detailImageUrl){
                            that.$message({
                                message: '请输入详情页图片',
                                type: 'warning'
                            });
                            return false;
                        }

                        if(!that.form.log){
                            that.$message({
                                message: '请输入旅行社log',
                                type: 'warning'
                            });
                            return false;
                        }
                        let para = Object.assign({}, this.form);
                        reqAddTourGroup(para).then((res) => {
                            if(res.data.code===1){
                                that.$message({
                                    message: '提交成功',
                                    type: 'success'
                                });
                                that.$router.push({path:"/tourGroup/list"});
                            }else {
                                that.$message({
                                    message: '提交失败',
                                    type: 'error'
                                });
                                that.$router.push({path:"/tourGroup/list"});
                            }
                        }).catch(()=>{
                            that.$message({
                                message: '提交失败',
                                type: 'error'
                            });
                        })
                    }
                });

            },

            //列表缩略图片
            beforeIndexImageUpload(file) {
                const isValidType = (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png');
                const isLt1M = file.size / 1024 / 1024 < 1;

                if (!isValidType) {
                    this.$message.error('不支持改类型的文件上传。');
                }
                if (!isLt1M) {
                    this.$message.error('上传文件大小不能超过 1MB!');
                }
                return isValidType && isLt1M;
            },
            handleIndexImageSuccess(response) {
                if (response.code === 1) {
                    this.form.indexImageUrl = response.data;
                    this.indexImageUrl=downloadFileURL + response.data + "/";
                    this.$refs.form.validateField('indexImageUrl');
                    console.log(this.indexImageUrl)
                }
            },

            //详情页图片
            beforeDetailImageUpload(file) {
                const isValidType = (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png');
                const isLt1M = file.size / 1024 / 1024 < 1;

                if (!isValidType) {
                    this.$message.error('不支持改类型的文件上传。');
                }
                if (!isLt1M) {
                    this.$message.error('上传文件大小不能超过 1MB!');
                }
                return isValidType && isLt1M;
            },
            handleDetailImageSuccess(response) {
                if (response.code === 1) {
                    this.form.detailImageUrl = response.data;
                    this.detailImageUrl=downloadFileURL + response.data + "/";
                    this.$refs.form.validateField('detailImageUrl');
                    console.log(this.detailImageUrl)
                }
            },

              //旅行社Log
            beforeLogUpload(file) {
                const isValidType = (file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png');
                const isLt1M = file.size / 1024 / 1024 < 1;

                if (!isValidType) {
                    this.$message.error('不支持改类型的文件上传。');
                }
                if (!isLt1M) {
                    this.$message.error('上传文件大小不能超过 1MB!');
                }
                return isValidType && isLt1M;
            },
            handleLogSuccess(response) {
                if (response.code === 1) {
                    this.form.log = response.data;
                    this.log=downloadFileURL + response.data + "/";
                    this.$refs.form.validateField('log');
                }
            },
        },
        mounted(){

        }
    }
</script>

<style scoped>

</style>
